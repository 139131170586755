import HomeScreen from './pages/HomeScreen.js'
import NavBar from './components/NavBar.js'
import './global.css';

function App() {

  return (
    <div>
      <NavBar/>
      <HomeScreen/>
    </div>
  );
}

export default App;
