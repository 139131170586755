import React, { useEffect, useState } from 'react';
import logo_name from '../assets/logo osat/logo nimi valkoinen.png';
import { useRef } from 'react';

const NavBarButton = ({ text, targetId, onClick }) => {
    const buttonRef = useRef(null);
  
    const handleClick = () => {
      // Scroll to the target element with offset
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        const yOffset = -200; // 200px offset
        const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    
      // Call the onClick function if provided
      if (onClick) {
        onClick();
      }
    };
  
    return (
      <button
        ref={buttonRef}
        onClick={handleClick}
        className="flex justify-center items-center px-6 py-3 text-white text-2xl font-medium bg-transparent"
      >
        {text}
      </button>
    );
  };

const DesktopNavBar = ({ scrollPosition }) => (
  <div
    className={`flex justify-center h-16 w-full ${
      scrollPosition < 10 ? 'bg-transparent backdrop-blur-sm' : 'backdrop-blur-none'
    }`}
  >
    <div className="flex space-x-4 h-full items-center">
      <NavBarButton text="Home" targetId="Home"/>
      <NavBarButton text="Gigs" targetId="Gigs"/>
      <NavBarButton text="Songs" targetId="Songs"/>
      <NavBarButton text="About Us" targetId="About Us"/>
      <NavBarButton text="Contact" targetId="Contact"/>
    </div>
  </div>
);

const MobileNavBar = ({ scrollPosition, isExpanded, toggleMenu }) => {
  const navItems = ['Home', 'Gigs', 'Songs', 'About Us', 'Contact'];

  return (
    <nav className="fixed top-0 right-0 z-50">
      <button
        onClick={toggleMenu}
        className={`fixed z-50 p-3 rounded-md transition-colors duration-300 top-1 right-4 w-16 h-16 bg-transparent text-white`}
      >
        <svg className="w-full h-full" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          {isExpanded ? (
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M6 18L18 6M6 6l12 12" />
          ) : (
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 6h16M4 12h16M4 18h16" />
          )}
        </svg>
      </button>

      <div
        className={`absolute top-0 right-0 w-screen h-screen flex flex-col items-center justify-center transform transition-transform duration-300 ease-in-out ${
          isExpanded ? 'backdrop-blur-sm bg-black bg-opacity-80 pointer-events-auto' : 'bg-transparent pointer-events-none'
        }`}
      >
        {navItems.map((item, index) => (
          <div
            key={item}
            className={`my-4 transition-all duration-300 transform ${
              isExpanded ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'
            }`}
            style={{ transitionDelay: `${index * 100}ms` }}
          >
            <NavBarButton 
              text={item} 
              onClick={isExpanded ? toggleMenu : undefined}
              targetId={item}
              className={isExpanded ? '' : 'pointer-events-none'}
            />
          </div>
        ))}
      </div>
    </nav>
  );
};

const NavBar = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [documentHeight, setDocumentHeight] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const resizeObserverRef = useRef(null);

  const updateDocumentHeight = () => {
    const newHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
    setDocumentHeight(newHeight);
  };

  useEffect(() => {
    const handleResize = () => {
      updateDocumentHeight();
      setIsMobile(window.innerWidth <= 1100);
    };

    // Initial update
    handleResize();

    // Set up ResizeObserver to detect changes in body height
    resizeObserverRef.current = new ResizeObserver(handleResize);
    resizeObserverRef.current.observe(document.body);

    // Set up event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const navBarProgress = (scrollPosition / (documentHeight - window.innerHeight)) * 100;

  return (
    <div
      className={`fixed inset-x-0 top-0 z-10 backdrop-blur-sm ${
        scrollPosition < 10 ? 'bg-transparent' : 'bg-gray-900/80 shadow-lg'
      } h-16`}
    >
      <div className="max-w-7xl mx-auto px-4 h-full">
        <div className="flex items-center h-full w-full justify-between">
          <img className={`object-cover top-2 left-0 ${isMobile ? "w-[60vw]" : "w-[40vh]"} max-w-[300px]`} src={logo_name} alt="Logo" />
          {isMobile ? (
            <MobileNavBar scrollPosition={scrollPosition} isExpanded={isExpanded} toggleMenu={toggleMenu} />
          ) : (
            <DesktopNavBar scrollPosition={scrollPosition} />
          )}
        </div>
      </div>

      <div
        className="h-1 bg-white bg-opacity-60 transition-all duration-500"
        style={{ width: `${navBarProgress}%` }}
      />
    </div>
  );
};

export default NavBar;