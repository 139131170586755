import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';

const ScrollBackground = ({ children, sections }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [sectionPositions, setSectionPositions] = useState([]);
  const [sectionHeights, setSectionHeights] = useState([]);
  const [totalHeight, setTotalHeight] = useState(0);
  const containerRef = useRef(null);

  const updateSectionInfo = () => {
    if (containerRef.current) {
      const newPositions = [];
      const newHeights = [];
      sections.forEach(section => {
        const element = document.getElementById(section.id);
        if (element) {
          newPositions.push(element.offsetTop);
          newHeights.push(element.offsetHeight);
        } else {
          newPositions.push(0);
          newHeights.push(0);
        }
      });
      setSectionPositions(newPositions);
      setSectionHeights(newHeights);
    }
  };

  const updateTotalHeight = () => {
    setTotalHeight(document.documentElement.scrollHeight);
  };

  useLayoutEffect(() => {
    // This will run after the initial render and layout
    updateSectionInfo();
    updateTotalHeight();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };

    const handleResize = () => {
      updateSectionInfo();
      updateTotalHeight();
    };

    const updateAllInfo = () => {
      updateSectionInfo();
      updateTotalHeight();
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    // Set up an interval to update heights and sections every half second
    const intervalId = setInterval(updateAllInfo, 500);

    // Run updates again after a short delay to catch any post-render changes
    const timeoutId = setTimeout(updateAllInfo, 100);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, [sections]);

  const getBackgroundColor = () => {
    let currentSectionIndex = sectionPositions.findIndex(
      (position, index) =>
        scrollPosition >= position &&
        (index === sectionPositions.length - 1 || scrollPosition < sectionPositions[index + 1])
    );
    if (currentSectionIndex === -1) currentSectionIndex = 0;
    const currentSection = sections[currentSectionIndex];
    const nextSection = sections[currentSectionIndex + 1];

    if (!nextSection) {
      return `rgb(${currentSection.color.r}, ${currentSection.color.g}, ${currentSection.color.b})`;
    }

    const currentPosition = sectionPositions[currentSectionIndex];
    const nextPosition = sectionPositions[currentSectionIndex + 1];
    const sectionLength = nextPosition - currentPosition;
    const sectionProgress = (scrollPosition - currentPosition) / sectionLength;
    const slow_progress = Math.pow(sectionProgress, 0.9);

    const r = Math.round(currentSection.color.r + (nextSection.color.r - currentSection.color.r) * slow_progress);
    const g = Math.round(currentSection.color.g + (nextSection.color.g - currentSection.color.g) * slow_progress);
    const b = Math.round(currentSection.color.b + (nextSection.color.b - currentSection.color.b) * slow_progress);

    return `rgb(${r}, ${g}, ${b})`;
  };

  return (
    <div
      ref={containerRef}
      style={{ backgroundColor: getBackgroundColor() }}
      className="min-h-screen transition-colors duration-300"
    >
      {children}
      <div style={{ display: 'none' }}>
        {`Total Page Height: ${totalHeight}px`}
        {sectionHeights.map((height, index) => (
          <span key={index}>{`Section ${index} Height: ${height}px`}</span>
        ))}
      </div>
    </div>
  );
};

export default ScrollBackground;