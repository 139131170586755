import React from 'react';
import { Mail, Phone, MapPin, Youtube, Instagram } from 'lucide-react';


const ContactFooter = () => {
  return (
    <footer className="bg-gray-900/80 shadow-lg text-white py-10">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-white text-6xl drop-shadow mb-8">Contact</h3>
            <ul className="space-y-3">
              <li className="flex items-center">
                <Mail className="mr-2" size={18} />
                <a href="mailto:info@example.com" className="hover:text-gray-300">pluton.bandoriginal@gmail.com</a>
              </li>
              <li className="flex items-center">
                <MapPin className="mr-2" size={18} />
                <span>Turku, Southwest Finland</span>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-2xl font-normal mb-4 mt-8">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="https://www.youtube.com/@Pluton_band" className="hover:text-gray-300">
                <Youtube size={24} />
              </a>
              <a href="https://www.instagram.com/plutonband_official/"  className="hover:text-gray-300">
                <Instagram size={24} />
              </a>
              <a href="https://www.tiktok.com/@plutonband_official"  className="hover:text-gray-300 fill-white stroke-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" fill="currentColor" class="bi bi-tiktok" viewBox="0 0 16 16">
                  <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"/>
                </svg>
              </a>
            </div>
          </div>
          <div>
            <h3 className="text-2xl font-normal mb-4 mt-8">Newsletter</h3>
            <form className="flex flex-col space-y-2">
              <input
                type="email"
                placeholder="Enter your email"
                className="px-4 py-2 bg-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                type="submit"
                className="w-full md:w-auto text-white text-xl md:text-2xl border px-4 py-2 bg-transparent"
                disabled={true}
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
        <div className="mt-[10vh] text-center">
          <p>&copy; {new Date().getFullYear()} Pluton. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};


export default ContactFooter;

