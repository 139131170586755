import React, { useState, useEffect } from 'react';
import { IoLocationOutline } from "react-icons/io5";
import { TfiEmail } from "react-icons/tfi";
import { IoMdPerson } from "react-icons/io";
import Dropdown from './Dropdown';


const MultilineText = ({ text, maxLines = 3 }) => {
    // Initialize the expanded state
    const [expanded, setExpanded] = useState(false);
    const lines = text.split('\n');
    const shouldShowButton = lines.length > maxLines;
  
    // Determine which lines to display based on the expanded state
    const displayedLines = expanded ? lines : lines.slice(0, maxLines);
  
    return (
      <div className="text-left mt-2 text-lg text-white font-thin">
        {displayedLines.map((line, index) => (
          <React.Fragment key={index}>
            {line}
            {index < displayedLines.length - 1 && <br />}
          </React.Fragment>
        ))}
        <br></br>
        {shouldShowButton && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(prev => !prev); // Toggle the expanded state
            }}
            className="mt-2 text-blue-400 hover:text-blue-300 focus:outline-none"
          >
            {expanded ? 'Read Less' : 'Read More'}
          </button>
        )}
      </div>
    );
  };
  


function GigBox(props) {
    const [reserveSpotEnabled, setReserveSpotEnabled] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [emailValue, setEmailValue] = useState('');
    const [emailError, setEmailError] = useState('');
    const [dropdownSelectedValue, setDropdownSelectedValue] = useState('1');

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleEmailValueChange = (event) => {
        setEmailValue(event.target.value);
        setEmailError('');
    };

    const handleSend = (e) => {
        e.preventDefault();
        if (!validateEmail(emailValue)) {
            setEmailError('Please enter a valid email address');
            return;
        }

        setReserveSpotEnabled(false);
        setEmailSent(true);

        const url = `https://send-verification-email-qbvqvud3iq-lm.a.run.app/?email=${emailValue}&gig=${props.concert_id}&people=${dropdownSelectedValue}&gig_name=${props.name}&date=${props.day+" "+props.month+" "+props.year+" "+props.hour}`;

        fetch(url, {
            method: 'POST',
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    const handleDropdownValueChange = (newValue) => {
        setDropdownSelectedValue(newValue);
    };

    return (
        <div className="w-full max-w-[90vw] mx-auto px-4 py-8">
            <div className='flex flex-col md:flex-row border border-white shadow-xl'>
                {/* Date section */}
                <div className='flex md:flex-col justify-between md:justify-center items-center md:items-start border-b md:border-b-0 md:border-r border-white p-4 md:p-6 md:w-1/5'>
                    <h1 className='text-white text-4xl md:text-5xl lg:text-7xl font-light'>{props.day}</h1>
                    <h1 className='text-white text-3xl md:text-4xl lg:text-6xl font-light md:mt-4'>{props.month}</h1>
                    <h1 className='text-white text-2xl md:text-3xl lg:text-5xl font-light md:mt-4'>{props.year}</h1>
                </div>
                {/* Main content section */}
                <div className='flex flex-col flex-grow p-4 md:p-6'>
                    {/* Event details */}
                    <div className='flex justify-between items-start mb-4'>
                        <h1 className='text-white text-4xl md:text-5xl lg:text-7xl font-light'>{props.name}</h1>
                        <h1 className="text-white font-light text-3xl md:text-4xl lg:text-5xl">{props.hour}</h1>
                    </div>
                    <MultilineText text={props.description}/>
                    {/* Location and reservation section */}
                    <div className='mt-6 flex flex-col md:flex-row justify-between items-start md:items-end'>
                        <div className='flex items-center mb-4 md:mb-0'>
                            <IoLocationOutline size={30} style={{ color: "white" }} />
                            <div className='ml-2'>
                                <h1 className='text-white font-light text-2xl md:text-3xl lg:text-4xl'>{props.location}</h1>
                                <h1 className='text-white font-light text-xl md:text-2xl lg:text-3xl'>{props.location2}</h1>
                            </div>
                        </div>
                        {!props.private_concert ? (
                            <div className='w-full md:w-auto'>
                                {!emailSent ? (
                                    !reserveSpotEnabled ? (
                                        <button
                                            onClick={() => setReserveSpotEnabled(true)}
                                            className="w-full md:w-auto text-white text-xl md:text-2xl border px-4 py-2 bg-transparent hover:bg-white hover:text-black transition-colors duration-300"
                                        >
                                            Reserve your spot
                                        </button>
                                    ) : (
                                        <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-2">
                                            
                                            {emailError && <p className="text-red-500">{emailError}</p>} {/* Error message conditionally rendered */}
                                            <div className="flex items-center w-full md:w-auto">
                                                <TfiEmail size={25} className="mr-2" style={{ color: "white" }} />
                                                <input
                                                    className="border w-full md:w-40 lg:w-60 h-15 bg-transparent text-xl p-3 text-white"
                                                    type="text" // Changed to "text" to match the second snippet
                                                    id="email"
                                                    name="email"
                                                    placeholder='Email'
                                                    value={emailValue}
                                                    onChange={handleEmailValueChange}
                                                />
                                            </div>
                                            <div className="flex items-center w-full md:w-auto">
                                                <IoMdPerson size={25} className="mr-2" style={{ color: "white" }} />
                                                <Dropdown value={dropdownSelectedValue} onChange={handleDropdownValueChange} />
                                            </div>
                                            <button
                                                type="submit"
                                                onClick={handleSend} // Added onClick to handle submit
                                                className="w-full md:w-auto text-white text-xl md:text-2xl border px-4 py-2 bg-transparent hover:bg-white hover:text-black transition-colors duration-300"
                                            >
                                                Send
                                            </button>
                                        </div>
                                    )
                                ) : (
                                    <p className="text-white text-xl md:text-2xl">Thank you for reserving your spot!</p>
                                )}
                            </div>) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GigBox;