import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectCoverflow, Navigation, Autoplay } from 'swiper/modules';
import song1_image from '../assets/songs/This love.webp';
import song2_image from '../assets/songs/New home.webp';
import song3_image from '../assets/songs/If you know.webp';
import song4_image from '../assets/songs/I dont wanna die.webp';
import song5_image from '../assets/songs/Beautiful world.webp';
import song6_image from '../assets/songs/Back in time.webp';
import { ChevronRight, ChevronLeft } from 'lucide-react';

const MultilineText = ({ text, maxLines = 3, expanded, onToggle }) => {
  const lines = text.split('\n');
  const shouldShowButton = lines.length > maxLines;

  const displayedLines = expanded ? lines : lines.slice(0, maxLines);

  return (
    <div className="text-center mt-2 text-sm text-white">
      {displayedLines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index < displayedLines.length - 1 && <br />}
        </React.Fragment>
      ))}
      {shouldShowButton && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onToggle();
          }}
          className="mt-2 text-blue-400 hover:text-blue-300 focus:outline-none"
        >
          {expanded ? 'Read Less' : 'Read More'}
        </button>
      )}
    </div>
  );
};

const ResponsiveCarousel = ({show_lyrics}) => {
  const slides = [
    { image: song1_image, title: 'THIS LOVE', lyrics: `Tonight, we are alone.
We are allowed to do what we want to.
This time, I want to show my love for you and everything.

I want to feel love.
I want to hear your voice.
I want to see the future.
We are falling in love.
I want to share all things.
I want to connect with love.
I want to know what love is.
We are falling in love.

Everything that ever comes up, we will take care of it together, no matter what it takes.  
All this that we've promised, what we'll promise to do, let love shine.

I want to feel love.
I want to hear your voice.
Forevermore with you, babe, we are falling in love.

This time, this time, this time, this time.
Everything we will ever do, everything we will ever see, I will always remember you, now and forever.
I will always take care after you, hugs and love whenever you need.
Now I know this is love.
This is true.` },
    { image: song2_image, title: 'NEW HOME', lyrics: `This is the night when I was left alone in the world.
Now I just run to the ends of the world in silence.
The ground repels every step I take, but I'm still trying to find a new home.

Where is the perfect home for me, a home where I can go when I have to?
Where is the place, where is the world, if I could find a new home?

I look at the stars in the sky and think that my new home is far from Earth.
I see a gray planet in the sky, and I know that is my home.  

Where is the perfect home for me, a home where I can go when I have to?

Where is the place, where is the world, if I could find a new home?
Pluto, Pluto, Pluto, Pluto is my home.

Where is the perfect home for me, a home where I can go when I have to?
Where is the place, where is the world, if I could find a new home?` },
    { image: song3_image, title: 'If you know', lyrics: `Every single moment I miss you, I believe we can still find these feelings.
Fly like birds in the sky towards the future and adventure.
The sea moves like the time of life; there is no time to hold back these feelings.
Now it feels like someone inside me is calling for me.

When we find love again?
Can we fly far away, like birds?

In the sky, the sun warms my skin; I feel a strange feeling inside me.
My heart is dying without your touch and love.
When we find love again?

Whatever, wherever, whenever, we are always together.
Sometimes true love never flies away.
True loves, true loves, true loves, true loves.
Whatever, wherever, whenever, we are always together.
Sometimes true love never fades away.
And this story must never end; you must always remember my love for you.` },
    { image: song4_image, title: 'I dont wanna die', lyrics: `I don’t wanna die
Today is the perfect day.
Me and my life are now okay.
My heart is beating for you.
That's why I don't wanna die tonight.

I don't wanna die tonight, die tonight.
I don’t wanna die tonight, I don’t wanna die.
I don’t wanna die without you, die all the time.
I don’t wanna die tonight.

From morning to night, I just work.
One day a year, I want to be free.
This is my only day off
That's why I don't wanna die tonight.

I don't wanna die tonight, die tonight.
I don’t wanna die tonight, I don’t wanna die.
I don’t wanna die without you, die all the time.
I don’t wanna die tonight.

I don't wanna die tonight, I don’t wanna die tonight.
I don’t wanna die tonight, I don’t wanna die.
I don’t wanna die without you, die all the time.
I don’t wanna die tonight.

I don't wanna die tonight, I don’t wanna die tonight.
I don’t wanna die tonight, I don’t wanna die.
I don’t wanna die without you, die all the time.
I don’t wanna die tonight, without you.` },
    { image: song5_image, title: 'Beautiful world', lyrics: `Waterfall, green fields, this is my dream. The sky is singing to me. Sunset and happy
tears, we are only children for a moment today.
This beautiful world
People love and are kind.
In my head, I think we are ready for the new.
Animals we have so many, why can't we be free like them? Seven seas, seven continents,
where is the most beautiful place? a place where I can close my eyes. keep going keep
going to future
This beautiful world
People love and are kind.
In my head, I think we are ready for the new world
i mean beautiful world
We have love and thousands of cultures. Different people from different places, and if we all
are friends for a moment to everyone, then we have a beautiful world.
And the world sings:
la la laaa laa laa
la laa laa laa laa lal la
laa laa la laa laa laa lala laa laa laa
This beautiful world
People love and are kind.
In my head, I think we are ready for the new world
i mean beautiful world` },
    { image: song6_image, title: 'Back in time', lyrics: `Back in time
This story begins now.
Thousands of memories from countless nights.
We were driving the car in the middle of the night.
And for a moment we were going back in time.

When we go back to driving, I feel like I'm going back.
Now it's clear that everything has changed, as we go back in time again.

Now I don't see my friends anymore, but sometimes I feel my memories coming back.
I step into my car and start driving again; I feel that feeling like I'm going back in time.  

When we go back to driving, I feel like I'm going back.
Now it's clear that everything has changed, as we go back in time again.

Back in time.` },
  ];

  const [slidesPerView, setSlidesPerView] = useState(3);
  const [expandedStates, setExpandedStates] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [autoplayEnabled, setAutoplayEnabled] = useState(true);
  const swiperRef = useRef(null);

  useEffect(() => {
    const isAnyExpanded = Object.values(expandedStates).some(state => state);
    setAutoplayEnabled(!isAnyExpanded);
  
    if (swiperRef.current && swiperRef.current.autoplay) {
      if (isAnyExpanded) {
        swiperRef.current.autoplay.stop();
      } else {
        swiperRef.current.autoplay.start();
      }
    }
  }, [expandedStates]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      let newSlidesPerView;

      if (screenWidth >= 1280) {
        newSlidesPerView = 3;
      } else if (screenWidth >= 768) {
        newSlidesPerView = 2;
      } else {
        newSlidesPerView = 1;
      }

      setSlidesPerView(newSlidesPerView);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial value

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSlideChange = (swiper) => {
    const newIndex = swiper.realIndex;
    if (newIndex !== activeIndex) {
      setActiveIndex(newIndex);
      setExpandedStates({});
    }
  };

  const toggleExpand = (index) => {
    setExpandedStates(prevStates => ({
      ...prevStates,
      [index]: !prevStates[index]
    }));
  };

  return (
    <div className={`flex justify-center items-center mx-auto`}>
      <div className="w-full max-w-6xl relative carousel-wrapper">
        <div className="carousel-container">
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={slidesPerView}
            loop={true}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 0,
              modifier: 1,
              slideShadows: false,
            }}
            autoplay={autoplayEnabled ? {
              delay: 4500,
              disableOnInteraction: false,
              reverseDirection: false,
            } : false}
            navigation={{
              prevEl: '.swiper-button-prev-custom',
              nextEl: '.swiper-button-next-custom',
            }}
            modules={[Autoplay, EffectCoverflow, Navigation]}
            className="mySwiper"
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            onSlideChange={handleSlideChange}
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index} className="slide-container">
                <div className="slide-content">
                  <img
                    src={slide.image}
                    alt={slide.title}
                    className="object-cover w-full h-auto"
                  />
                  <h3 className="text-center mt-4 text-xl font-semibold text-white">{slide.title}</h3>
                  {show_lyrics ? (
                    <MultilineText
                      text={slide.lyrics}
                      expanded={expandedStates[index] || false}
                      onToggle={() => toggleExpand(index)}
                    />
                  ) : null}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="swiper-button-prev-custom">
          <ChevronLeft strokeWidth={2} size={50} />
        </div>
        <div className="swiper-button-next-custom">
          <ChevronRight strokeWidth={2} size={50} />
        </div>
      </div>
      <style jsx>{`
        .carousel-wrapper {
          position: relative;
          padding: 0 60px; /* Increased padding to accommodate arrows */
        }
        .carousel-container {
          overflow: hidden;
        }
        .swiper-slide {
          transition: all 0.3s ease;
        }
        .swiper-slide-active {
          transform: scale(1.2);
          z-index: 2;
        }
        .swiper-slide-prev,
        .swiper-slide-next {
          opacity: 0.3;
        }
        .swiper-button-prev-custom,
        .swiper-button-next-custom {
          position: absolute;
          top: 50%;
          margin-top: -20px;
          z-index: 10;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          border-radius: 50%;
        }
        .swiper-button-prev-custom {
          left: 5px;
        }
        .swiper-button-next-custom {
          right: 5px;
        }
        .swiper-container {
          padding: 60px 0;
        }
        .slide-container {
          width: 100%;
          height: 100%;
        }
        @media (max-width: 768px) {
          .carousel-wrapper {
            padding: 0 50px; /* Slightly less padding on mobile, but still enough for arrows */
          }
        }
      `}</style>
    </div>
  );
};

export default ResponsiveCarousel;