import React, { useEffect, useState } from 'react';
import logo1 from '../assets/logo osat/logo valkoinen-1.png';
import logo2 from '../assets/logo osat/logo valkoinen-2.png';
import logo3 from '../assets/logo osat/logo valkoinen-3.png';
import logo4 from '../assets/logo osat/logo valkoinen-4.png';

function AnimatedLogo() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  
    useEffect(() => {
      const handleScroll = () => {
        setScrollPosition(window.pageYOffset);
      };
      const handleResize = () => {
        setIsDesktop(window.innerWidth > 768);
      };
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    const scrollToElement = (targetId) => {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        const yOffset = -200; // 200px offset
        const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    };
  
    // Calculate rotation for each image
    const rotation1 = Math.min(scrollPosition * 0.1, 135);
    const rotation2 = Math.min(scrollPosition * 0.4, 135);
    const rotation3 = Math.min(scrollPosition * 0.15, 135);
    const rotation4 = Math.min(scrollPosition * 0.08, 135);
  
    // Define directions and dynamic styles with transition
    const rotateStyle1 = { transform: `rotate(${rotation1}deg)`, transition: 'transform 3s ease-out' };
    const rotateStyle2 = { transform: `rotate(${-rotation2}deg)`, transition: 'transform 3s ease-out' };
    const rotateStyle3 = { transform: `rotate(${rotation3}deg)`, transition: 'transform 2s ease-out' };
    const rotateStyle4 = { transform: `rotate(${-rotation4}deg)`, transition: 'transform 2.5s ease-out' };
  
    const logoSize = isDesktop ? '60vh' : '80%';
  
  
    return (
      <div className={`flex flex-col items-center justify-${isDesktop ? 'between' : 'start'} min-h-screen py-[35px]`}>
        <div className={`relative flex-grow flex items-center justify-center w-full ${!isDesktop ? 'mt-[-10vh]' : ''}`}>
          <div
            className="relative aspect-square max-w-[500px] max-h-[500px] "
            style={{ width: logoSize, height: logoSize }}
          >
            <img
              className="absolute w-full h-full"
              style={rotateStyle1}
              src={logo1}
              alt="Logo 1"
            />
            <img
              className="absolute w-full h-full"
              style={rotateStyle2}
              src={logo2}
              alt="Logo 2"
            />
            <img
              className="absolute w-full h-full"
              style={rotateStyle3}
              src={logo3}
              alt="Logo 3"
            />
            <img
              className="absolute w-full h-full"
              style={rotateStyle4}
              src={logo4}
              alt="Logo 4"
            />
          </div>
          {!isDesktop && (
            <div className="absolute bottom-[80px] z-10">
              <button
                onClick={() => { scrollToElement('About Us'); }}
                className="w-[10rem] h-12 border-solid border-2 border-white bg-transparent text-2xl font-thin text-white hover:bg-white hover:text-black transition-colors duration-300"
              >
                About us
              </button>
            </div>
          )}
        </div>
        {isDesktop && (
          <div className="z-10">
            <button
              onClick={() => { scrollToElement('About Us'); }}
              className="w-60 h-12 border-solid border-2 border-white bg-transparent text-3xl font-thin text-white hover:bg-white hover:text-black transition-colors duration-300"
            >
              About us
            </button>
          </div>
        )}
      </div>
    );
  }


export default AnimatedLogo;
