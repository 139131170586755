import React, { useState } from 'react';
import image from '../assets/about-us-kuva-bw.webp';

const MultilineText = ({ text}) => {
  const lines = text.split('\n');

  return (
    <div className="text-left mt-2 text-lg text-white">
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {<br />}
        </React.Fragment>
      ))}
     
    </div>
  );
};

const AboutUs = () => {
  const fullText = `Our band was formed in the summer of 2023 when our vocalist, Jesper, was searching for a band. After a long search, he suddenly received a friend request on Snapchat. It was Ilari who added him. At that moment, we had no idea what would come next. We chatted for a couple of days before meeting. When we finally met for the first time, it felt natural, and the conversation flowed easily. We quickly started looking for other band members because, well, you can’t make music with just two people, right? At least we didn’t know that was possible at the time. Soon, a new member joined the band, and we met for the first time as a trio in the center of Turku. Now we had a drummer, vocalist, and keyboardist. Our next goal was to find a practice space. Ilari knew about Turku Rock Academy, which operates in Auran Panimo, and we visited the space on the same day. The great facilities and friendly people convinced us that this was the place where we could get everything we needed. August 28th, 2023, was the day PLUTON had its first band practice. For that first session, we had created a list of cover songs to practise, but soon we transitioned from covers to creating our own music and style. By the next training, we had already laid the foundation for PLUTON's very first original song!
While practicing, we kept searching for more band members. Soon, a guitarist joined, and the band finally felt complete. The first autumn was chaotic, and we didn’t really know how to make music properly. Our rehearsals were sporadic, and it wasn’t something we took seriously. However, Jesper and Ilari’s friendship quickly grew, and they wanted to start making music more seriously. Our band went through a lot of bumps during the fall and winter, which led to two members leaving. The band was now hit by a real tidal wave, and we didn’t know what to do. The only remaining members were the original ones—Jesper and Ilari. We took a short break to consider whether it was worth continuing PLUTON's journey.
We kept calling and messaging each other about the future of the band, but the decision to continue was on shaky ground. Opinions about continuing the band varied, and perspectives changed almost daily. Some days we wanted to give the band another chance, while on other days, stopping felt like the better option. At some point, we started to seriously consider leaving PLUTON’s story behind. However, we had spent such an amazing six months together, and our collaboration worked so well that we thought it would be unfair to waste our potential. The thought that, if we continued and eventually succeeded, kept lingering in our minds. Finally, we decided to keep the band going. We might not have believed in the band, but we believed in each other and in our collaboration. The search for new members began again. After exhausting efforts and outside pressures, we decided to make music as a duo. Spring passed, and we had created several songs. The feeling within PLUTON was great, and it seemed like we were on the verge of hitting the big stages with these songs.
However, things took a sharp turn. On April 24th, 2024, our band practice was interrupted by Mark, who was involved in music at Panimo. He came to check how we were doing. We presented our work, and he seemed to like it. However, he gave us a professional perspective and told us that the songs were far from finished. The work would have to start all over again. He offered us the opportunity to take PLUTON’s songs to a new level, and we wanted to give it a shot. A few days later, the new chapter of our story began, which is now moving forward at full speed. On April 26th, 2024, the atmosphere was tense. We met a new person, Mikko, who had made a new version of one of our songs and incorporated electronic elements. Hearing our song for the first time, our jaws practically dropped—it was so grand and amazing. After that, we weren’t sure if we were ready to leave the old PLUTON behind and take a completely new approach to making music. We decided to start fresh. We began the work, and Mikko joined us in arranging our songs. The two-member band PLUTON was born. Its members are Jesper and Ilari, and they want to keep pushing PLUTON’s story forward.
PLUTON is a band that aims to bring something new and something old to the world. The secret to PLUTON’s songs is the 80s vibe fused with modern music. Our songs tell stories about our own lives and thoughts on the world. We want to be the band that does more than just make music—we want to inspire and help others achieve their own dreams. The purpose of our songs is to reflect the different situations life can throw at you. We encourage people to face all kinds of emotions: love, joy, sadness, fear… These are the themes of our songs, and they offer a journey into music through the lens of PLUTON. We genuinely want to make music—and make it right. We haven’t achieved anything yet, other than sweat on our foreheads. The story is just beginning, and our focus is now on the band. We have our first album ready, and we are set to start performing it. Not everything has been easy—that much we know. This journey is just beginning, and we want you to be part of it.
With love,
PLUTON`;
  const shortText = fullText.split(" ").slice(0, 60).join(" ") + (fullText.split(" ").length > 60 ? '...' : '');
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="max-w-6xl mx-auto p-4 sm:p-10">
      <div className="relative">
        <img
          src={image}
          alt="About Us"
          className="w-full sm:w-1/2 h-80 object-cover sm:float-left sm:mr-8 sm:mb-8 mb-4 drop-shadow-xl"
        />
        <div className={isExpanded ? "text-white clear-both sm:clear-none": "text-white clear-both sm:clear-none mb-[20vh]"}>
          <div className="text-lg font-thin drop-shadow-xl">
            {isExpanded ? <MultilineText text={fullText} /> : shortText}
          </div>
          <button
            onClick={toggleText}
            className="mt-4 text-white text-xl border px-4 py-2 bg-transparent hover:bg-white hover:text-black transition-colors duration-300">
            {isExpanded ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;