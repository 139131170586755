import React, { useState, useEffect } from 'react';

import image from '../assets/image.png';
import GigBox from '../components/GigBox.js';
import AboutUs from '../components/AboutUs.js';
import ResponsiveCarousel from '../components/SongCarousel.js';
import ContactFooter from '../components/ContactFooter.js';
import ScrollBackground from '../components/ScrollBackground.js';
import AnimatedLogo from '../components/AnimatedLogo.js';
import InstagramFeed from '../components/InstagramFeed.js';


function HomeScreen() {
  const [sections] = useState([
    { id: "Home", color: { r: 20, g: 30, b: 70 } },
    { id: "Gigs", color: { r: 20, g: 30, b: 70 } },
    { id: "Songs", color: { r: 150, g: 35, b: 80 } },
    { id: "About Us", color: { r: 35, g: 100, b: 150 } },
    { id: "Contact", color: { r: 10, g: 30, b: 50 } },
  ]);
  useEffect(() => {
    document.title = 'PLUTON';
  }, []);

  const concert2_description = `The PLUTON IS MY HOME album will be performed live for the first time at this concert.
Doors Open: 18:45
Show Time: 19:15
Entrance: Please enter through the lower doors.
If you have reserved your seat, you can proceed directly to participate. Your seat reservation code is in your email—if you cannot locate it, please check your spam folder. Have your code ready before reaching the door to speed up entry.
If you arrive without a reservation, please note that entry cannot be guaranteed. The concert venue has a maximum capacity of 180 people.
Parking: If you arrive by car, please park at the Ruskotalo parking lot. Parking is free of charge, but spaces are limited. We encourage attendees to come to the concert by means other than a car. Bicycle parking is available in front of Ruskotalo.
Amenities: A buffet will be available during the concert. Hearing protectors are also provided at the buffet, but due to limited availability, we recommend bringing your own.
Duration: The concert will last approximately 30-45 minutes.
Please note that bringing your own food or drinks is prohibited, except for water in a reusable water bottle. All dangerous items are strictly prohibited.
We wish you a wonderful evening and warmly welcome you to the BACK IN TIME concert!`

  return (
    <ScrollBackground sections={sections}>
      <div className="relative" id="Home">
        <img className='overlay-2' src={image} alt="Background" />
        <div className='overlay-1'>
          <AnimatedLogo className="w-[40vh]" />
        </div>
      </div>
      <div id="Gigs">
        <h1 className='text-white text-5xl md:text-6xl lg:text-7xl drop-shadow ml-[10%] mt-[5%]'>
          Gigs
        </h1>
        <h2 className='flex text-white text-2xl md:text-3xl lg:text-4xl drop-shadow mx-auto mt-[5%] justify-center font-thin ml-[1%] mr-[1%]'>
          No planned gigs at the moment
        </h2>
        {/*<div className='mt-[3rem]'>
          <GigBox concert_id="BACK_IN_TIME_1" name="BACK IN TIME" day="8" month="Nov" year="2024" hour="10:00" location="Maunun koulu" location2="Rusko" private_concert={true} description="PRIVATE" />
        </div>
        <div className='mt-[2rem]'>
          <GigBox concert_id="BACK_IN_TIME_2" name="BACK IN TIME" day="8" month="Nov" year="2024" hour="19:15" location="Ruskotalo" location2="Rusko" private_concert={false} description={concert2_description} />
        </div>*/}
      </div>
      <div className='mt-[30vh]' id="Songs">
        <h1 className='text-white text-5xl md:text-6xl lg:text-7xl drop-shadow ml-[10vw] mt-[5rem] mb-[5rem]'>
          Songs
        </h1>
        <ResponsiveCarousel show_lyrics={false} />
      </div>
      <div className='mt-[30vh] mb-[20vh]' id="About Us">
        <h1 className='text-white text-5xl md:text-6xl lg:text-7xl drop-shadow ml-[10vw] mt-[5rem]'>
          About us
        </h1>
        <AboutUs />
      </div>
      <div className='mt-[20vh]' id="Contact">
        <ContactFooter />
      </div>
    </ScrollBackground>
  );
}

export default HomeScreen;